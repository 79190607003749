export default (() => {
  const { loadScriptAsync } = window.wafer.utils
  const DISPATCHER_SCRIPT =
    process.env.NODE_ENV === 'development'
      ? 'https://s.yimg.com/bu/js/dispatcherWidget.js'
      : 'https://s.yimg.com/kj/js/dispatcherWidget.js'
  class Dispatcher {
    constructor({ selector }) {
      const matchingDivs = [...document.querySelectorAll(selector)]
      if (!matchingDivs.length) {
        return
      }
      loadScriptAsync({ src: DISPATCHER_SCRIPT }, () => {
        const contexts = []
        let appId
        let cobrand
        matchingDivs.forEach(eachDiv => {
          try {
            const {
              appId: applicationId,
              channelId,
              mode,
              cobrand: appCobrand,
              responsive = true,
              showOnElementClass,
              showOnElementClass: { elementId, class: cls } = {},
              mediaModes
            } = JSON.parse(eachDiv.getAttribute('data-config'))
            contexts.push({
              ...(channelId && { name: channelId }),
              targetElementId: eachDiv.id,
              responsive,
              mode,
              ...(mediaModes && { mediaModes }),
              ...(showOnElementClass && { showOnElementClass: { elementId, class: cls } })
            })
            appId = applicationId
            cobrand = appCobrand
          } catch (e) {
            console.error(`Error parsing dispatcher config ${e}`)
          }
        })
        if (!appId || !contexts.length) {
          return
        }
        const widgetConfig = {
          appId,
          ...(cobrand ? { cobrand } : {}),
          namespace: 'aol',
          contexts
        }
        if (window.dispatcherWidget) {
          window.dispatcherWidget.initialize(widgetConfig)
        }
      })
    }
  }
  return Dispatcher
})()
